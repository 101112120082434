






































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import CheckboxInput from '~/components/shared/inputs/Checkbox.vue'

@Component({
  components: {
    CheckboxInput,
  },
})
export default class MapSelect extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: false }) count: Boolean
  @Prop({ default: (): any[] => [] }) options: any[]
  @Prop({ default: (): any[] => [] }) selectedOptions: any[]
  @Prop({ default: true }) showBuilderCount: Boolean
  @Prop({ default: true }) toUppercaseTitle: boolean
  @Prop({ default: false }) toBoldFont: boolean
  @Prop({ default: true }) useTextBrandBase: boolean
  @Prop({ default: true }) addPaddingTitle: boolean

  isDisabled(value: any) {
    if (!this.selectedOptions.length) return

    const regexAllAny = /^all_|^any_/

    const allOrAnySelected = this.selectedOptions.some((opt) =>
      regexAllAny.test(opt.value)
    )

    if (allOrAnySelected) return !regexAllAny.test(value)
    else return regexAllAny.test(value)
  }

  handleCheck($event: any) {
    const selectedOpts = [...this.selectedOptions]
    const chkbxValue = $event.target.value
    const chkbxChecked = $event.target.checked

    if (chkbxChecked) {
      const option = this.options.find(
        (opt) => opt.value.toString() === chkbxValue
      )

      selectedOpts.push(option)
    } else {
      const optionIndex = selectedOpts.findIndex(
        (opt) => opt.value.toString() === chkbxValue
      )

      selectedOpts.splice(optionIndex, 1)
    }

    this.$emit('handleCheck', selectedOpts)
  }
}
