import {
  prop,
  required,
  numeric,
  maxNumber,
  NumericValueType,
} from '@rxweb/reactive-forms'
import Elevation from '../elevation'
import FormStep from './FormStep'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'
import { userStore } from '~/store'
import { Project } from '~/models'

const getMaterialsControls = (isSpecialFeatureEnabled: boolean): any[] => {
  const controls: any[] = [
    {
      id: 'primary_material_category',
      label: 'Primary Material',
      type: InputType.buttonSelect,
      parentClass: 'w-full',
      required: true,
      settings: {
        description:
          'Select the material that comprises the majority of the front facade',
        options: [],
        titleSize: LabelSizes.extraLarge,
      },
    },
  ]

  if (isSpecialFeatureEnabled) {
    controls.push({
      id: 'special_feature',
      label: 'Special Feature',
      type: InputType.select,
      parentClass: 'w-full',
      required: false,
      settings: {
        description: '',
        options: [],
        titleSize: LabelSizes.extraLarge,
      },
    })
  } else {
    controls.push({
      id: 'secondary_material_category',
      label: 'Secondary Material',
      type: InputType.buttonSelect,
      parentClass: 'w-full',
      required: true,
      settings: {
        description:
          'Select the material used that comprises the minority of the front facade',
        options: [],
        titleSize: LabelSizes.extraLarge,
      },
    })
  }

  return controls
}

export default class ElevationForm {
  @required({ message: 'Name is required' }) name: string
  @required({ message: 'Style is required' }) style: number
  @prop() front_porch: boolean
  @required({ message: 'Primary Material is required' })
  primary_material_category: string

  @required({ message: 'Secondary Material is required' })
  secondary_material_category: string

  @required({ message: 'Roof Material is required' })
  roof_material_category: string

  @prop() special_feature: string

  @prop() roof_type: number
  @prop() photo: number[] = []

  plan: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Price must be greater than 0',
  })
  @maxNumber({
    value: 5000000,
    message: 'Starting price amount must be less than $5,000,000',
  })
  price: number = 0

  constructor(elevation: Elevation) {
    if (elevation) {
      const photo = elevation.photo ? [elevation.photo.id] : [null]
      this.name = elevation.name
      this.front_porch = elevation.front_porch
      this.roof_type = elevation.roof_type || undefined
      this.photo = photo

      this.primary_material_category =
        elevation.primary_material_category &&
        elevation.primary_material_category.toLocaleLowerCase()

      this.roof_material_category =
        elevation.roof_material_category &&
        elevation.roof_material_category.toLocaleLowerCase()

      this.secondary_material_category =
        elevation.secondary_material_category &&
        elevation.secondary_material_category.toLocaleLowerCase()

      this.special_feature =
        elevation.special_feature && elevation.special_feature

      this.style = elevation.style as unknown as number
      if (elevation.price) {
        this.price = elevation.price
      }
    }
  }

  get project(): Project {
    return userStore.project
  }

  static steps(): FormStep[] {
    return [
      {
        title: 'Overview',
        step: 1,
        valid: false,
        controls: [
          {
            id: 'name',
            label: 'Name',
            heapId: 'elevation_name',
            required: true,
            type: InputType.text,
            parentClass: 'w-1/2 pr-6',
            settings: {
              description: 'Enter the marketing name for this elevation',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'front_porch',
            label: 'Front Porch',
            type: InputType.toggle,
            parentClass: 'w-1/2 pl-6',
            required: false,
            settings: {
              description: 'Does this elevation include a front porch?',
              titleSize: LabelSizes.extraLarge,
              prefix: 'No',
              suffix: 'Yes',
              options: [
                {
                  value: false,
                  label: 'No',
                },
                {
                  value: true,
                  label: 'Yes',
                },
              ],
            },
          },
          {
            id: 'style',
            label: 'Architectural Style',
            type: InputType.buttonSelect,
            parentClass: 'w-full mt-5',
            required: true,
            settings: {
              description:
                'Select the style of this elevation. This style will be used when adding sales and spec homes.',
              titleSize: LabelSizes.extraLarge,
              options: [],
            },
          },
          {
            id: 'price',
            label: 'Price',
            heapId: 'form-elevation_price',
            parentClass: 'w-full',
            type: InputType.currency,
            required: false,
            settings: {
              description: 'Enter the elevation price',
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        title: 'Materials',
        step: 2,
        valid: false,
        controls: getMaterialsControls(userStore.isSpecialFeatureEnabled),
      },
      {
        title: 'Roofing',
        step: 3,
        valid: false,
        controls: [
          {
            id: 'roof_material_category',
            label: 'Roof Material',
            type: InputType.buttonSelect,
            parentClass: 'w-full',
            required: true,
            settings: {
              description:
                'Select the roofing material type used on this elevation',
              options: [],
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'roof_type',
            label: 'Roofing Type',
            type: InputType.buttonSelect,
            parentClass: 'w-full',
            required: false,
            settings: {
              description:
                'Select the roof configuration used by this elevation (if applicable).',
              options: [],
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        title: 'Uploads',
        step: 4,
        valid: false,
        controls: [
          {
            id: 'photo',
            label: 'Photo/Rendering',
            type: InputType.fileUpload,
            parentClass: 'w-full',
            required: false,
            settings: {
              accept: '.jpeg,.jpg,.png',
              files: [],
              description:
                'Upload a single image file here for the elevation. (Note: this image may display on the consumer-facing map.)',
              titleSize: LabelSizes.extraLarge,
              category: 'photo',
            },
          },
        ],
      },
    ]
  }
}
