




































































































































import { mixins, Component, Emit, Prop } from 'nuxt-property-decorator'
import globalStyling from '~/mixins/globalStyling'
import { ConsumerMapRoutes } from '~/enums/consumerMap'
import { getLocalStorageItem, storageAvailable } from '~/utils/localStorage'
import { favoritesStore } from '~/store'

@Component({
  name: 'MobileNavBar',
  mixins: [globalStyling],
})
export default class MobileNavBar extends mixins(globalStyling) {
  @Prop({ default: 'map' }) mapActiveView: 'map' | 'grid'
  isShareWithMeVisible: boolean = false

  pagesVisibilitySettings: {
    map: boolean
    plans: boolean
    favorites: boolean
  } = {
    map: true,
    plans: true,
    favorites: true,
  }

  async loadProjectsSpecificStyle(projectId: string) {
    await import(`assets/css/${projectId}.css`)
  }

  getTargetRoute(targetRoute: string): any {
    const routes = this.$router.options.routes
    if (routes.length) {
      const foundRoute = routes.find((route) => route.name === targetRoute)
      return foundRoute.path.replace(':slug', this.$route.params.slug)
    }
  }

  async mounted() {
    await this.loadProjectsSpecificStyle(this.$route.params.slug)
    this.isShareWithMeVisible = storageAvailable()
      ? !!getLocalStorageItem('shared-with-me')
      : false
    this.pagesVisibilitySettings = this.getPagesVisibilitySettings()
  }

  getActiveRoute(): string {
    return this.$route.path.split('/').pop()
  }

  get isFavoritesOrSharedRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES ||
      this.getActiveRoute() === ConsumerMapRoutes.SHARED
    )
  }

  get isFavoritesRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES &&
      !this.isShareWithMeVisible
    )
  }

  get favCount() {
    return favoritesStore.favCount
  }

  getPagesVisibilitySettings(): any {
    return {
      map: this.isVisible('--v3-cm-page-map'),
      plans: this.isVisible('--v3-cm-page-plans'),
      favorites: this.isVisible('--v3-cm-page-favorites'),
    }
  }

  @Emit('showMobileFilters')
  showMobileFilters(value: boolean): boolean {
    return value
  }
}
