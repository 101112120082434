







































































import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import { search } from '~/api/lot.api'
import Spinner from '~/components/shared/Spinner.vue'
import { Lot } from '~/models'

@Component({
  components: {
    Spinner,
  },
})
export default class Search extends Vue {
  items: any = []
  isLoading: boolean = false
  searchTerm: string | null = null

  @Prop({ default: true }) redirectOnClick: boolean
  @Prop({ default: true }) isAbsolutePosition: boolean
  @Prop({ default: (): Array<any> => [] }) queryParams: Array<any>
  @Prop({ default: false }) cleanOnSelectd: boolean
  @Prop({ default: '' }) value: string
  @Prop() excludeItem: { propertyName: string; value: string }

  mounted() {
    if (this.value) {
      this.searchTerm = this.value
    }
  }

  get forrmatedItems() {
    if (this.items.length === 0) {
      return []
    } else {
      return this.items.map((item: any) => ({
        id: item.parcel_id,
        title: item.address,
        centerpoint: item.centerpoint ? item.centerpoint.coordinates : null,
        description: `Lot ${item.number} - Phase ${item.phase} - Block ${item.block}`,
        link: `/lots/${item.slug.replace(/\s+/g, '-').toLowerCase()}`,
      }))
    }
  }

  getQueryParams(): string {
    if (!this.queryParams) {
      return ''
    }
    return this.queryParams.join('&')
  }

  async search() {
    try {
      this.items = []
      if (this.searchTerm) {
        this.isLoading = true
        const response = await search(this.searchTerm, this.getQueryParams())
        if (this.excludeItem) {
          this.items = response.results.filter(
            (item: any) =>
              item[this.excludeItem.propertyName] &&
              item[this.excludeItem.propertyName] !== this.excludeItem.value
          )
        } else {
          this.items = response.results
        }
        this.isLoading = false
      }
    } catch (error) {
      this.isLoading = false
    }
  }

  redirect(path: string) {
    this.searchTerm = null
    this.$router.push(path)
  }

  @Emit('itemSelected')
  returnCompleteItem(item: any): any {
    const found: Lot = this.items.find(
      (_item: any) => _item.parcel_id === item.id
    )

    if (this.cleanOnSelectd) {
      this.items = []
      this.searchTerm = found.address
    }

    return found
  }

  @Emit('onItemClicked')
  returnItemSelected(item: any): any {
    this.searchTerm = null
    this.returnCompleteItem(item)
    return item
  }
}
