import { BuilderContract, Lot } from '~/models'
import { $axios } from '~/utils/api'

const END_POINT = '/builder'

// Object to store ongoing requests
const ongoingRequests = {}

const getMaterialCategories = (builderId: number): Promise<string[]> => {
  const requestKey = `${END_POINT}/${builderId}/material_categories`

  if (!ongoingRequests[requestKey]) {
    ongoingRequests[requestKey] = $axios.$get(requestKey).finally(() => {
      delete ongoingRequests[requestKey]
    })
  }

  return ongoingRequests[requestKey]
}

const getContracts = (builderId: number): Promise<BuilderContract[]> =>
  $axios.$get(`${END_POINT}/${builderId}/contracts`)

const getDevelopmentLots = (
  builderId: number,
  contractId?: number
): Promise<Lot[]> =>
  $axios.$get(
    `${END_POINT}/${builderId}/development_lots${
      contractId ? `?contract=${contractId}` : ''
    }`
  )

export { getMaterialCategories, getContracts, getDevelopmentLots }
