



















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { Navigation, Project, User } from '~/models'
import { navigation, showNavOption } from '~/utils/navigationAuth'

@Component({
  computed: {
    ...mapGetters('user', {
      user: 'user',
      project: 'project',
      isAdmin: 'isAdmin',
      isGuest: 'isGuest',
      isDeveloper: 'isDeveloper',
      isBuilder: 'isBuilder',
    }),
  },
})
export default class Sidenav extends Vue {
  user: User
  isDeveloper: boolean
  isAdmin: boolean
  project: Project
  isGuest: boolean
  isBuilder: boolean
  items: Array<Navigation> = navigation

  @Prop() showNav: boolean = false

  showOption(item: any): boolean {
    return showNavOption(
      item,
      this.project,
      this.isDeveloper,
      this.user,
      this.isGuest,
      this.isAdmin,
      this.isBuilder
    )
  }

  @Emit()
  toggleNav() {
    return false
  }
}
