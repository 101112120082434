import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'

@Module({
  name: 'project',
  stateFactory: true,
  namespaced: true,
})
export default class Project extends VuexModule implements BaseState {
  currentProject: number = null

  get activeProject(): number {
    return this.currentProject
  }

  @Action({ rawError: true })
  changeActiveProject(id: number) {
    this.setActiveProject(id)
  }

  @Mutation
  setActiveProject(id: number) {
    this.currentProject = id
  }

  @Mutation
  clear(): void {
    this.currentProject = null
  }
}
