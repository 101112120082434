

















































import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import LogRocket from 'logrocket'
import { Places } from '~/enums/heap'
import { Project } from '~/models'
import { storageAvailable } from '~/utils/localStorage'

@Component({
  middleware: ['logrocket'],
  computed: {
    ...mapGetters('consumer-map', {
      project: 'project',
    }),
  },
})
export default class MapViewLayout extends Vue {
  project: Project

  mounted() {
    // @ts-ignore
    const url: string = this.$environmentConfig.baseURL
    if (url.includes('rpg') || url.includes('hillwood')) {
      LogRocket.identify('consumer-map-v2-user', {
        name: 'map user',
        email: 'mapv2@thexo.io',
        subscriptionType: 'pro',
      })
    }
  }

  @Watch('project')
  onProjectChange() {
    // @ts-ignore
    if (this.$heap) this.$heap(null, null, this.project, Places.MAP_V2)
  }

  get isStorageEnabled(): boolean {
    return storageAvailable()
  }
}
