













































import { Component, Emit, Prop } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import BaseInputComponent from './BaseInput.vue'
import TitleInput from './TitleInput.vue'

@Component({
  components: {
    TitleInput,
  },
  computed: {
    ...mapGetters('constants', {
      age_choices: 'age_choices_list',
    }),
  },
})
export default class ChildrenInput extends BaseInputComponent {
  selectedChoices: string[] = []

  age_choices: string[][]
  @Prop({ default: null })
  prefix: string

  @Prop({ default: null })
  suffix: string

  @Prop({ default: false })
  required: boolean

  constructor() {
    super()
    this.selectedChoices = this.value || []
  }

  @Emit('validateField')
  handleBlur(): any {}

  ageFormated(age_choice: string) {
    const choice = age_choice.split(' ')
    if (/\d/.test(choice[0])) {
      const num = choice[0]
      const ageChoice = choice.slice(1).join(' ').concat(` (${num})`)
      return ageChoice
    } else {
      return choice.join(' ')
    }
  }

  choiceUpdated($event: any, choice: string) {
    const qty = +$event.target.value
    this.selectedChoices = this.selectedChoices.filter((c) => c !== choice)

    for (let i = 0; i < qty; i++) {
      this.selectedChoices.push(choice)
    }
    this.handleInput(this.selectedChoices)
  }

  getValue(age_choice: string) {
    return this.selectedChoices
      ? this.selectedChoices.filter((choice) => choice === age_choice).length
      : 0
  }
}
