/* eslint-disable no-async-promise-executor */
/* eslint-disable no-prototype-builtins */
import routes from '~/constants/routerPath.js'

const ACCEPTED_HTTP_METHODS = ['get', 'post', 'patch', 'put', 'delete']

/**
 *
 * @param {Object} obj an object
 * @description This function will traverse an object in deep looking for empty objects
 * and will replace then with a null value
 * @returns {void}
 */
function replace(obj) {
  for (const key in obj) {
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      !Array.isArray(obj[key])
    ) {
      if (Object.keys(obj[key]).length === 0) {
        obj[key] = null
      } else {
        replace(obj[key])
      }
    }
  }
}

export default function ({
  $axios,
  store,
  redirect,
  $environmentConfig,
  route,
}) {
  $axios.setBaseURL($environmentConfig.baseURL)
  $axios.onRequest((config) => {
    const token = store.state.auth.token
    const projectId =
      store.state.project.currentProject ||
      store.state['consumer-map'].currentProject?.id
    const currentBuilder = store.state.builder?.currentBuilder
    const userRole = store.state.user?.currentUser?.role
    const projectSlug = store.state['consumer-map'].currentProject?.slug

    const isGoogleMapsRequest = config.url.includes(
      'https://maps.googleapis.com'
    )
    const isMapboxDirectionRequest = config.url.includes(
      'https://api.mapbox.com'
    )

    // this is an exception for the public maps(sales and rental) requests
    const isPublicMapRequest = [
      'map-slug',
      'v3-map-slug',
      'v3-map-slug-plans',
      'v3-map-slug-favorites',
      'v3-map-slug-shared-with-me',
      'v3-rental_map-slug',
      'v3-rental_map-slug-favorites',
      'v3-rental_map-slug-shared-with-me',
    ].includes(route.name)

    if (token && !isGoogleMapsRequest && !isPublicMapRequest) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (
      ACCEPTED_HTTP_METHODS.includes(config.method) &&
      (projectId || projectSlug)
    ) {
      if (!isMapboxDirectionRequest) {
        if (!isPublicMapRequest) {
          config.url = config.url
            .concat(config.url.includes('?') ? '&' : '?')
            .concat(`project_id=${projectId}`)
          if (currentBuilder && userRole === 'builder') {
            config.url = config.url.concat(`&collaborator_id=${currentBuilder}`)
          }
        } else {
          if (config.url.includes(`project=${projectSlug}`)) {
            return
          }
          config.url = config.url
            .concat(config.url.includes('?') ? '&' : '?')
            .concat(`project=${projectSlug}`)
        }
      }
    }

    if (['post', 'patch', 'put'].includes(config.method)) {
      replace(config.data)
    }
  })

  $axios.onError((error) => {
    return new Promise(async (resolve, reject) => {
      const statusCode = error.response ? error.response.status : -1
      const url = error.config?.url || ''

      if (url.includes('dashboards') && statusCode === 401) {
        return reject(error.response?.data)
      }

      const badResponseCodes = [401, 422]
      if (!url.includes('/login/') && badResponseCodes.includes(statusCode)) {
        if (url.includes('/login/refresh/')) {
          return redirect(routes.login)
        }

        const refreshToken = store.state.auth.refreshToken
        if (refreshToken) {
          if (error.config.hasOwnProperty('retryAttempts')) {
            return redirect(routes.login)
          } else {
            const config = { retryAttempts: 1, ...error.config }
            try {
              await store.dispatch('auth/refresh')
              return resolve($axios(config))
            } catch (e) {
              return redirect(routes.login)
            }
          }
        } else {
          return redirect(routes.login)
        }
      }
      return reject(error.response?.data)
    })
  })

  const originalFetch = window.fetch
  window.fetch = function (originalRequest, options) {
    // Intercept the request before it is sent, the intention is to add a timestamp so
    // every request can be treated as check if this eliminates the ramdom errors happening with
    // the mapbox requests being pulled from cache

    if (!originalRequest.url) {
      return originalFetch
        .call(this, originalRequest, options)
        .then((response) => {
          return response
        })
        .catch((error) => {
          throw error
        })
    }

    let request = originalRequest
    const isUrlFromMapbox = originalRequest.url.includes(
      'https://api.mapbox.com/'
    )

    if (isUrlFromMapbox) {
      let finalUrl = originalRequest.url
      if (finalUrl.includes('?')) {
        finalUrl += '&'
      }
      finalUrl += 'timestamp=' + Date.now()
      request = new Request(finalUrl, {
        ...request,
        url: finalUrl,
      })
    }

    return originalFetch
      .call(this, request, options)
      .then((response) => {
        return response
      })
      .catch((error) => {
        // Handle request errors
        console.error('Request error:', error)
        throw error
      })
  }
}
