import { $axios } from '~/utils/api'
import { PagedResponse } from '~/models'
import { NetBuilderContract } from '~/models/builderContract'

const END_POINT = process.env.VUE_APP_CONTRACTS_API_URL

// Note a projectId is required to get the builder contracts
// Other parameters are optional: query, status, page, pageSize

const getBuilderContracts = (
  queryFilters?: string,
  page?: any,
  ordering?: string,
  pageSize?: number
): Promise<PagedResponse<NetBuilderContract>> => {
  let url = `${END_POINT}/BuilderContracts/Search${
    queryFilters ? `?${queryFilters}` : ''
  }`

  if (page) {
    url = url.includes('?') ? `${url}&page=${page}` : `${url}?page=${page}`
  }

  if (ordering) {
    url = url.includes('?')
      ? `${url}&ordering=${ordering}`
      : `${url}?ordering=${ordering}`
  }

  if (pageSize) {
    url = url.includes('?')
      ? `${url}&page_size=${pageSize}`
      : `${url}?page_size=${pageSize}`
  }

  return $axios.get(url).then((response) => response.data)
}

const getBuilderContract = (id: number) => $axios.$get(`${END_POINT}/${id}`)

const getBuilders = () => $axios.$get(`${END_POINT}/Builders`)

export { getBuilderContracts, getBuilderContract, getBuilders }
